.container {
    display: flex;
    flex-wrap: wrap;
    padding: 3vh;
}

img {
     width: 45vh;
     height: 45vh;
     padding: 2vh;
}

h5 {
    padding: 2vh;
}