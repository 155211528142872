.post-picture{
    width: 45vh;
    height: 45vh;
}

.post-text-label{
    width: 15vh;
}

.post-description{
    height: 45vh;
}

.invalid-span{
    margin-left: 20vh;
    color: red;
}